import axios from '../utils/index';
import { Pat } from '@/typings/request';
const subscriptionApi = {
    getSubscriptionList(data: Pat, callback: (T: any) => void) {
        axios
            .get(
                `/member-payment-records-entity/list?pageNum=${data.pageNum}&pageSize=10&startTime=${data.startTime}&endTime=${data.endTime}&keyword=${data.keyword}`,
                {
                    data,
                }
            )
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default subscriptionApi;
