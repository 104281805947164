import React, { useCallback, useEffect, useState } from 'react';
import { Input, Button, Popover, Modal, DatePicker } from 'antd';
import './index.scss';
import TableBox from '@/components/tableBox';
import Pagination from '@/components/pagination';
import { debounce } from 'lodash';
import api from '@/api/prize';
import { defaultInfo } from '@/typings/allType';
import { useNavigate } from 'react-router-dom';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
const { TextArea } = Input;
const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current < dayjs().endOf('day');
};
const Prize = (props: any) => {
    useEffect(() => {
        getList({ ...state });
    }, []);
    const [status, setStatus] = useState('showData');
    const navigate = useNavigate();
    const [totalRecord, setTotalRecord] = useState(0);
    const [state, setState] = useState({
        pageNo: 1,
        search: '',
    });
    const [isSearch, setIsSearch] = useState(false);
    const searchList: any = useCallback(
        debounce(params => {
            getList({ ...params });
        }, 1000),
        []
    );
    const handlePageChange = prop => {
        setState({ ...state, pageNo: prop });
        getList({ ...state, pageNo: prop });
    };
    const getList = (val: defaultInfo) => {
        if (status === 'loading') return;
        const params = {
            pageNum: val.pageNo,
            pageSize: 10,
            keyword: val.search,
        };
        setStatus('loading');
        api.getPrizeList(params, res => callback(res));
    };
    const callback = (res: defaultInfo) => {
        const { data, code, message } = res;
        if (code === 200) {
            if (data.records.length > 0) {
                const list = data.records.map(item => {
                    return Object.assign(item, {
                        popoverOpen: false,
                        firstDrawDateShow:
                            item.firstDrawDate && item.firstDrawDate !== null
                                ? window.format.foramtTimeDMYTime(+item.firstDrawDate)
                                : '-',
                    });
                });
                setTableData(list);
                setStatus('showData');
                setTotalRecord(data.total);
            } else {
                setStatus('showData');
                setTableData([]);
                setTotalRecord(data.total);
            }
        } else {
            setStatus('empty');
            window.format.alert('error', message);
        }
        setIsSearch(false);
    };
    const [tableData, setTableData] = useState<any>([]);
    const columns = [
        {
            title: 'Prize Name',
            dataIndex: '',
            key: 'prizeName',
            width: 450,
            render: val => (
                <div className="cell-txt r-left" style={{ color: val.isOpen ? '#222222' : '#F30A0A' }}>
                    {val.prizeName ?? '-'}
                </div>
            ),
        },
        {
            title: 'Points',
            dataIndex: '',
            key: 'drawPoints',
            render: val => (
                <div className="cell-txt" style={{ color: val.isOpen ? '#222222' : '#F30A0A' }}>
                    {val.drawPoints ?? '-'}
                </div>
            ),
        },
        {
            title: 'Mode',
            dataIndex: '',
            key: 'mode',
            render: val => (
                <div className="cell-txt" style={{ color: val.isOpen ? '#222222' : '#F30A0A' }}>
                    {val.mode === 2 ? 'Cycle by days' : 'Once '}
                </div>
            ),
        },
        {
            title: 'Cycle number',
            dataIndex: '',
            key: 'numberCycles',
            render: val => (
                <div className="cell-txt" style={{ color: val.isOpen ? '#222222' : '#F30A0A' }}>
                    {val.numberCycles ?? '0'}
                </div>
            ),
        },
        {
            title: 'Latest Draw Date',
            dataIndex: '',
            key: 'firstDrawDate',
            render: val => (
                <div className="cell-txt" style={{ color: val.isOpen ? '#222222' : '#F30A0A' }}>
                    {val.firstDrawDateShow ?? '-'}
                </div>
            ),
        },
        {
            title: '',
            dataIndex: '',
            key: 'prizeId',
            render: (val, info, i) => (
                <div className="cell-txt">
                    <Popover
                        placement="bottomRight"
                        title={''}
                        trigger="click"
                        open={val.popoverOpen}
                        onOpenChange={val => {
                            tableData[i].popoverOpen = val;
                            setTableData([...tableData]);
                        }}
                        content={
                            <div className="tableOpeation-box">
                                <div
                                    className={`tb_item ${val.isOpen ? 'danger' : 'success'}`}
                                    onClick={() => {
                                        opeationBtnClcik(3, val, i);
                                    }}
                                >
                                    {val.isOpen ? 'Stop' : 'Start'} the draw
                                </div>
                                <div
                                    className="tb_item"
                                    onClick={() => {
                                        opeationBtnClcik(2, val, i);
                                    }}
                                >
                                    Edit
                                </div>
                                <div
                                    className="tb_item"
                                    onClick={() => {
                                        tableData[i].popoverOpen = false;
                                        setTableData([...tableData]);
                                        setOpeationPrize({ ...val });
                                        setDelDialog(true);
                                    }}
                                >
                                    Remove
                                </div>
                            </div>
                        }
                        arrow={false}
                    >
                        <i
                            className="more_icon"
                            onClick={() => {
                                tableData[i].popoverOpen = true;
                                setTableData([...tableData]);
                            }}
                        ></i>
                    </Popover>
                </div>
            ),
        },
    ];

    const [opeationPrize, setOpeationPrize] = useState<defaultInfo>({});
    const [opeationType, setOpeationType] = useState(1); // 1 add 2 edit 3 stop
    const [prizeDialog, setPrizeDialog] = useState(false);
    const [delDialog, setDelDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const opeationBtnClcik = (type: number, val?: defaultInfo, i?: number) => {
        if (i !== undefined) {
            tableData[i].popoverOpen = false;
            setTableData([...tableData]);
        }
        if (type === 3) {
            // 切换状态
            const value = val as defaultInfo;
            api.changePrizeStatus({ prizeId: value.prizeId, isOpen: !value.isOpen }, res => {
                const { data, code, message } = res;
                setLoading(false);
                if (code === 200) {
                    getList({ ...state });
                } else {
                    window.format.alert('error', message);
                }
            });
            return;
        }
        setOpeationType(type);
        if (type === 2) {
            const value = val as defaultInfo;
            setPrizeInfo({
                prizeId: value.prizeId,
                prizeName: value.prizeName,
                prizeDescription: value.prizeDescription,
                drawPoints: value.drawPoints,
                firstDrawDate: value.firstDrawDate ? dayjs(+value.firstDrawDate) : '',
                mode: value.mode,
                cycleDays: value.cycleDays,
                numberCycles: value.numberCycles,
            });
        }
        setPrizeDialog(true);
    };
    const confirmOpeationClick = () => {
        // 确认删除
        api.delPrizr({ prizeId: opeationPrize.prizeId }, res => {
            const { data, code, message } = res;
            setLoading(false);
            if (code === 200) {
                setDelDialog(false);
                getList({ ...state });
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const [isError, setIsError] = useState('');
    const [prizeInfo, setPrizeInfo] = useState({
        prizeName: '',
        prizeDescription: '',
        drawPoints: '',
        firstDrawDate: null as any,
        mode: 2,
        cycleDays: '',
        numberCycles: '',
        prizeId: '',
    });
    const reSetPrizeInfo = () => {
        setPrizeInfo({
            prizeName: '',
            prizeDescription: '',
            drawPoints: '',
            firstDrawDate: null as any,
            mode: 2,
            cycleDays: '',
            numberCycles: '',
            prizeId: '',
        });
    };
    const confirmSave = () => {
        if (!prizeInfo.prizeName) {
            window.utils.alert('error', 'Please enter the prize name');
            return;
        }
        if (!+prizeInfo.drawPoints) {
            window.utils.alert('error', 'Please enter points');
            return;
        }
        if (!prizeInfo.firstDrawDate) {
            window.utils.alert('error', 'Please select the date of the draw');
            return;
        }
        if (prizeInfo.mode === 2 && !Number(prizeInfo.cycleDays)) {
            window.utils.alert('error', 'Please enter cycle days');
            setIsError('');
            return;
        }
        if (prizeInfo.mode === 2 && !Number(prizeInfo.numberCycles)) {
            window.utils.alert('error', 'Please enter cycle times');
            setIsError('');
            return;
        }
        setIsError('');
        const pat: defaultInfo = {
            ...prizeInfo,
            firstDrawDate: window.utils.getPrizeOpenTime(prizeInfo.firstDrawDate),
        };
        if (opeationType === 2) {
            pat.prizeId = prizeInfo.prizeId;
            pat.firstDrawDate = prizeInfo.mode === 2 ? '' : pat.firstDrawDate;
            api.updatePrize(pat, opeationCallback);
            return;
        }
        api.addPrize(pat, opeationCallback);
    };
    const opeationCallback = res => {
        const { data, code, message } = res;
        setLoading(false);
        if (code === 200) {
            setPrizeDialog(false);
            reSetPrizeInfo();
            getList({ ...state });
        } else {
            window.format.alert('error', message);
        }
    };
    return (
        <div className="prize_main table_page page_mainBox">
            <h2>Prize </h2>
            <div className="workout_top r-left">
                <Input
                    placeholder="Name "
                    style={{ width: 335 }}
                    value={state.search}
                    allowClear
                    onChange={e => {
                        setIsSearch(true);
                        setState({ ...state, search: e.target.value, pageNo: 1 });
                        searchList({ ...state, search: e.target.value, pageNo: 1 });
                    }}
                    className="r-input r-input_serach"
                    prefix={<span className="search_icon"></span>}
                />
                <div className="space15"></div>
                <div className="r-left marLeft">
                    <Button className="r-emBorder_btn" style={{ width: 150 }} onClick={() => navigate('/member-prize')}>
                        Member prize
                    </Button>
                    <div className="space15"></div>
                    <Button className="r-primary_btn" style={{ width: 150 }} onClick={() => opeationBtnClcik(1)}>
                        Add new prize
                    </Button>
                </div>
            </div>
            <TableBox
                status={status}
                columns={columns}
                data={tableData}
                rowKey={val => val.prizeId}
                height={false}
                emptyText={{
                    emptyText:
                        (state.search && !isSearch) || (isSearch && !state.search) ? (
                            <div className="table_emptyBox">
                                Sorry, we were unable to find any results based on your conditions.
                            </div>
                        ) : (
                            <div className="table_emptyBox">No prizes have been added</div>
                        ),
                }}
                className="is_clickable r-table table_main"
                rowClassName={() => 'r-table_row'}
            />
            {status === 'showData' && tableData.length && totalRecord > 10 ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
            ) : (
                <></>
            )}

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={delDialog}
                closeIcon={<></>}
                onCancel={() => setDelDialog(false)}
                footer={''}
                key={'prizeDel-dialog-1'}
                style={{ width: 480 }}
                title={[
                    <div className="modal-top-title" style={{ marginBottom: 30 }}>
                        Remove prize
                    </div>,
                ]}
            >
                <div className="modal_form">
                    <p className="modal_tips">Are you sure you want to remove this prize?</p>
                    <div className="r-left" style={{ marginTop: 40 }}>
                        <Button
                            className="r-emBorder_btn"
                            type="primary"
                            style={{ width: '100%', margin: '0 20px 0 0' }}
                            onClick={() => setDelDialog(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            style={{ width: '100%' }}
                            loading={loading}
                            onClick={() => confirmOpeationClick()}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
            {/* add or edit */}
            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={prizeDialog}
                closeIcon={<></>}
                onCancel={() => setPrizeDialog(false)}
                footer={''}
                key="prizeDialog-1"
                style={{ width: 480 }}
                title={[
                    <div className="modal-top-title" style={{ marginBottom: 30, textAlign: 'left' }}>
                        {opeationType === 1 ? ' Add new prize' : ' Edit prize'}
                    </div>,
                ]}
            >
                <div className="modal_form">
                    <section>
                        <p className="form_tip">Prize Name</p>
                        <Input
                            className="r-input"
                            placeholder="Enter prize name"
                            value={prizeInfo.prizeName}
                            onInput={(e: any) => setPrizeInfo({ ...prizeInfo, prizeName: e.target.value })}
                            maxLength={50}
                        />
                        <p className="form_tip">Description (optional)</p>
                        <TextArea
                            className="r-input"
                            autoSize={{ minRows: 4, maxRows: 4 }}
                            placeholder="About prize value or details"
                            maxLength={100}
                            value={prizeInfo.prizeDescription}
                            onInput={(e: any) => setPrizeInfo({ ...prizeInfo, prizeDescription: e.target.value })}
                        />
                        <p className="form_tip">Draw points</p>
                        <Input
                            className="r-input"
                            maxLength={5}
                            placeholder="Enter points"
                            value={prizeInfo.drawPoints}
                            onInput={(e: any) => {
                                e.target.value = window.format.validateNumberInput(e.target.value);
                                setPrizeInfo({ ...prizeInfo, drawPoints: e.target.value });
                            }}
                        />
                        <p className="form_tip">Draw Date</p>
                        <DatePicker
                            disabledDate={disabledDate}
                            suffixIcon={<i className="chevron-down_icon"></i>}
                            className="r-downTimePick"
                            placeholder="Select"
                            inputReadOnly={true}
                            value={prizeInfo.firstDrawDate}
                            disabled={prizeInfo.mode === 2 && opeationType === 2}
                            format={'DD MMM YYYY'}
                            onChange={val => {
                                setPrizeInfo({ ...prizeInfo, firstDrawDate: val });
                            }}
                        />
                        <p className="form_tip">Mode</p>
                        <div className="r-left">
                            <div
                                className="prize_modeCheck r-left"
                                style={{ cursor: opeationType === 2 ? 'no-drop' : 'pointer' }}
                                onClick={() => {
                                    if (opeationType === 2) return;
                                    setPrizeInfo({ ...prizeInfo, mode: 1, cycleDays: '', numberCycles: '' });
                                }}
                            >
                                <i
                                    style={{ cursor: opeationType === 2 ? 'no-drop' : 'pointer' }}
                                    className={`radio_icon ${prizeInfo.mode === 1 ? 'radioChecked_icon' : ''}`}
                                ></i>
                                <span className="prmar10">Once</span>
                            </div>
                            <div
                                className="prize_modeCheck r-left"
                                style={{ cursor: opeationType === 2 ? 'no-drop' : 'pointer' }}
                                onClick={() => {
                                    if (opeationType === 2) return;
                                    setPrizeInfo({ ...prizeInfo, mode: 2 });
                                }}
                            >
                                <i
                                    style={{ cursor: opeationType === 2 ? 'no-drop' : 'pointer' }}
                                    className={`radio_icon ${prizeInfo.mode === 2 ? 'radioChecked_icon' : ''}`}
                                ></i>
                                <span className="prmar10">Cycle by days</span>
                            </div>
                        </div>
                        {prizeInfo.mode === 2 ? (
                            <div>
                                <Input
                                    className="r-input pzmarttop"
                                    style={{ borderColor: isError === 'cycleDays' ? '#F30A0A' : '#E4E4E4' }}
                                    onInput={(e: any) => {
                                        e.target.value = window.format.validateNumberInput(e.target.value);
                                        setPrizeInfo({ ...prizeInfo, cycleDays: e.target.value });
                                    }}
                                    value={prizeInfo.cycleDays}
                                    maxLength={3}
                                    suffix="Day(s)"
                                    placeholder=""
                                />
                                <Input
                                    className="r-input pzmarttop"
                                    style={{ borderColor: isError === 'numberCycles' ? '#F30A0A' : '#E4E4E4' }}
                                    onInput={(e: any) => {
                                        e.target.value = window.format.validateNumberInput(e.target.value);
                                        setPrizeInfo({ ...prizeInfo, numberCycles: e.target.value });
                                    }}
                                    value={prizeInfo.numberCycles}
                                    maxLength={4}
                                    suffix="Time(s)"
                                    placeholder=""
                                />
                            </div>
                        ) : (
                            ''
                        )}
                    </section>
                    <div className="r-left" style={{ marginTop: 40 }}>
                        <Button
                            className="r-emBorder_btn"
                            type="primary"
                            style={{ width: '100%', margin: '0 20px 0 0' }}
                            onClick={() => {
                                setPrizeDialog(false);
                                reSetPrizeInfo();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            style={{ width: '100%' }}
                            loading={loading}
                            disabled={
                                !prizeInfo.prizeName &&
                                !prizeInfo.prizeDescription &&
                                !prizeInfo.drawPoints &&
                                !prizeInfo.firstDrawDate &&
                                prizeInfo.mode === 2 &&
                                (!prizeInfo.cycleDays || !prizeInfo.numberCycles)
                            }
                            onClick={() => confirmSave()}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default Prize;
