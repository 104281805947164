import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, DatePicker, Select } from 'antd';
import './style/index.scss';
import TableBox from '@/components/tableBox';
import Pagination from '@/components/pagination';
import { debounce } from 'lodash';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { defaultInfo } from '@/typings/allType';
import api from '@/api/user';
const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
};
const { RangePicker } = DatePicker;
const User = (props: any) => {
    const navigate = useNavigate();
    useEffect(() => {
        getList({ ...state });
    }, []);
    useEffect(() => {
        const path = props.prevLocation?.pathname;
        if (path === '/user-detail') {
            getList({ ...state });
        }
    }, [props.prevLocation]);

    const [status, setStatus] = useState('showData');
    const [totalRecord, setTotalRecord] = useState(100);
    const [state, setState] = useState({
        pageNo: 1,
        search: '',
        startTime: '' as any,
        endTime: '' as any,
        status: '-1',
    });
    const [isSearch, setIsSearch] = useState(false);
    const searchList: any = useCallback(
        debounce(params => {
            getList({ ...params });
        }, 1000),
        []
    );
    const changeTime = val => {
        if (val && val !== null) {
            const startTime = dayjs(val[0]).format('YYYY-MM-DD');
            const endTime = dayjs(val[1]).format('YYYY-MM-DD');
            setState({ ...state, startTime: startTime, endTime, pageNo: 1 });
            getList({ ...state, endTime, startTime, pageNo: 1 });
            return;
        }
        setState({ ...state, endTime: '', startTime: '', pageNo: 1 });
        getList({ ...state, endTime: '', startTime: '', pageNo: 1 });
    };
    const handlePageChange = prop => {
        setState({ ...state, pageNo: prop });
        getList({ ...state, pageNo: prop });
    };
    const getList = val => {
        if (status === 'loading') return;
        const params = {
            pageNum: val.pageNo,
            pageSize: 10,
            startTime: val.startTime ? dayjs(`${val.startTime} 00:00:00`).valueOf() : '',
            endTime: val.endTime ? dayjs(`${val.endTime} 23:59:59`).valueOf() : '',
            keyword: val.search,
            status: val.status === '-1' ? '' : val.status,
        };
        setStatus('loading');
        api.getUserList(params, res => callback(res));
    };
    const callback = (res: defaultInfo) => {
        const { data, code, message } = res;
        if (code === 200) {
            if (data.records.length > 0) {
                const list = data.records.map(item => {
                    return Object.assign(item, {
                        createTime: item.createTime ? window.format.foramtTimeDMY_easy(+item.createTime) : '-',
                        mobileNumber: item.mobileNumber ? window.format.formatNumber(item.mobileNumber) : '-',
                        subscription:
                            item.subscriptionId === '3'
                                ? 'Gold'
                                : item.subscriptionId === '2'
                                ? 'Silver'
                                : item.subscriptionId === '1'
                                ? 'Bronze'
                                : 'Freemium',
                    });
                });
                setTableData(list);
                setStatus('showData');
                setTotalRecord(data.total);
            } else {
                setStatus('showData');
                setTableData([]);
                setTotalRecord(data.total);
            }
        } else {
            setStatus('empty');
            window.format.alert('error', message);
        }
        setIsSearch(false);
    };
    const [tableData, setTableData] = useState([1, 2, 3]);
    const columns = [
        {
            title: 'Full Name',
            dataIndex: '',
            key: 'displayName',
            render: val => <div className="cell-txt r-left">{val.displayName ?? '-'}</div>,
        },
        {
            title: 'Email Address',
            dataIndex: '',
            key: 'email',
            render: val => <div className="cell-txt">{val.email ?? '-'}</div>,
        },
        {
            title: 'Phone Number',
            dataIndex: '',
            key: 'mobileNumber',
            render: val => <div className="cell-txt">{val.mobileNumber ?? '-'}</div>,
        },
        {
            title: 'Membership',
            dataIndex: '',
            width: 150,
            key: 'subscription',
            render: val => <div className="cell-txt">{val.subscription ?? '-'}</div>,
        },
        {
            title: 'Registration Date',
            key: 'createTime',
            width: 150,
            render: val => <div className="cell-txt">{val.createTime ?? '-'}</div>,
        },
    ];
    const handClickRow = row => {
        window.sessionStorage.setItem('userInfo', JSON.stringify(row));
        navigate('/user-detail');
    };

    return (
        <div className="user_main table_page page_mainBox">
            <h2>Users</h2>
            <div className="user_top r-left">
                <Input
                    placeholder="Name/Email/Phone"
                    style={{ width: 335 }}
                    value={state.search}
                    allowClear
                    onChange={e => {
                        setIsSearch(true);
                        searchList({ ...state, search: e.target.value, pageNo: 1 });
                        setState({ ...state, search: e.target.value, pageNo: 1 });
                    }}
                    className="r-input r-input_serach"
                    prefix={<span className="search_icon"></span>}
                />
                <div className="space15"></div>
                <RangePicker
                    className={`r-datePicker r-date_showLine`}
                    style={{ width: 335 }}
                    format="DD/MM/YYYY"
                    inputReadOnly
                    onChange={changeTime}
                    disabledDate={disabledDate}
                    suffixIcon={<></>}
                    separator={<div></div>}
                    placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
                />
                <div className="space15"></div>
                <Select
                    style={{ width: 180, height: 48 }}
                    className="r-select r-select48"
                    value={state.status ? state.status : void 0}
                    placeholder="Select"
                    suffixIcon={<i className="sel_arrow_icon"></i>}
                    onChange={val => {
                        setState({ ...state, status: val });
                        getList({ ...state, pageNo: 1, status: val });
                    }}
                    options={[
                        { value: '-1', label: 'All membership' },
                        { value: '3', label: 'Gold' },
                        { value: '2', label: 'Silver' },
                        { value: '1', label: 'Bronze' },
                        { value: '0', label: 'Freemium' },
                    ]}
                />
            </div>
            <TableBox
                status={status}
                columns={columns}
                data={tableData}
                rowKey={val => val.workoutRewardsId}
                height={false}
                emptyText={{
                    emptyText:
                        state.search || state.startTime || state.endTime || isSearch ? (
                            <div className="table_emptyBox">
                                Sorry, we were unable to find any results based on your conditions.
                            </div>
                        ) : (
                            ''
                        ),
                }}
                className="is_clickable r-table table_main click_row"
                rowClassName={() => 'r-table_row'}
                onRow={record => {
                    handClickRow(record);
                }}
            />
            {status === 'showData' && totalRecord > 0 && totalRecord > 10 ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
            ) : (
                <></>
            )}
        </div>
    );
};
export default User;
