import Tailor from '@/components/tailor/tailor';
import { Button, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '@/api/survey';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { defaultInfo } from '@/typings/allType';
import uploadApi from '@/api/upload';

const NewCategory = props => {
    const [loading, setLoading] = useState(false);
    const [categoryName, setCategoryName] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [avatar, setAvatar] = useState({
        url: '',
        key: '',
        base64: '',
        file: null,
        uploading: false,
        dialogVisible: false,
        warncontent: '',
    }) as any;
    const handleFileChange = (e: any) => {
        if (!e) {
            return;
        }
        const file = e.target.files[0].name.split('.');
        if (file[file.length - 1].toLowerCase() === 'png' || file[file.length - 1].toLowerCase() === 'jpg') {
            if (e.target.files[0].size > 10 * 1048576) {
                window.format.alert('error', 'Pictures cannot exceed 10MB');
                (document.getElementById('imgInput') as any).value = null;
                return;
            }
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function (ev: any) {
                setAvatar(
                    Object.assign({}, avatar, {
                        file: ev.target.result,
                        dialogVisible: true,
                    })
                );
                (document.getElementById('imgInput') as any).value = null;
            };
        } else {
            window.format.alert('error', 'File format error');
            (document.getElementById('imgInput') as any).value = null;
        }
    };
    const getResult = (e: any) => {
        setAvatar(
            Object.assign({}, avatar, {
                url: '',
                base64: '',
                dialogVisible: false,
                file: null,
                uploading: true,
            })
        );
        const param = new FormData();
        param.append('file', e);
        const reader = new FileReader();
        reader.readAsDataURL(e);
        reader.onload = function (ev: any) {
            const dataURL = ev.target.result;
            uploadApi.uploadFile(param, (res: any) => {
                if (!res) {
                    return;
                }
                if (res.code === 200) {
                    setAvatar(
                        Object.assign({}, avatar, {
                            url: res.data,
                            key: window.format.getFilePathName(res.data),
                            base64: dataURL,
                            dialogVisible: false,
                            file: null,
                            uploading: false,
                        })
                    );
                } else {
                    setAvatar({
                        url: '',
                        key: '',
                        base64: '', //
                        file: null,
                        uploading: false,
                        dialogVisible: false,
                        warncontent: '',
                    });
                    window.format.alert('error', res.data.message);
                }
            });
        };
    };
    const stopUplaod = (url?: string) => {
        setAvatar({
            url: url ? url : '',
            key: '',
            base64: '',
            file: null,
            uploading: false,
            dialogVisible: false,
        });
    };
    const submit = async () => {
        if (!avatar.url) {
            window.utils.alert('error', 'Please upload icon picture');
            return;
        }
        if (!categoryName) {
            window.utils.alert('error', 'Please enter the category name');
            return;
        }
        const pat: defaultInfo = {
            categoryName: categoryName,
            icon: avatar.url,
        };
        if (props.type === 'edit') {
            pat.questionCategoryId = categoryId;
            api.updateCategory(pat, callback);
            return;
        }
        api.addCategory(pat, callback);
    };
    const callback = res => {
        const { data, code, message, metaData } = res;
        setLoading(false);
        if (code === 200) {
            props.setCategoryDialog(false);
            props.type === 'add'
                ? props.getList()
                : props.setCategoryInfo({
                      ...props.val,
                      categoryName: categoryName ? window.utils.removeEmojis(categoryName) : '',
                      icon: avatar.url,
                      questionCategoryId: categoryId,
                  });
        } else {
            window.format.alert('error', message);
        }
    };
    useEffect(() => {
        if (!props.categoryDialog) {
            stopUplaod();
            setCategoryName('');
        }
        if (props.type === 'edit') {
            setCategoryName(props.val.categoryName);
            setCategoryId(props.val.questionCategoryId);
            stopUplaod(props.val.icon);
        }
    }, [props.categoryDialog]);
    return (
        <>
            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={props.categoryDialog}
                closeIcon={<></>}
                onCancel={() => props.setCategoryDialog(false)}
                footer={''}
                key={1}
                style={{ width: 480 }}
                title={[
                    <div className="modal-top-title" style={{ marginBottom: 30, textAlign: 'left' }}>
                        {props.type === 'edit' ? 'Edit category' : 'Create new category'}
                    </div>,
                ]}
            >
                <div className="modal_form">
                    <section>
                        <p className="form_tip">Icon</p>
                        <div className="r-left uploadMain_box">
                            <label
                                className="upload_imgBox"
                                htmlFor="imgInput"
                                style={{
                                    backgroundImage: `url(${avatar.base64 ? avatar.base64 : avatar.url})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center center',
                                }}
                            >
                                {avatar.base64 || avatar.url ? (
                                    <></>
                                ) : (
                                    <>
                                        {!avatar.uploading ? (
                                            <PlusOutlined rev={undefined} style={{ fontSize: 24, color: '#c5c5c5' }} />
                                        ) : (
                                            <LoadingOutlined style={{ fontSize: 22 }} rev={undefined} />
                                        )}
                                    </>
                                )}
                                <input
                                    type="file"
                                    id="imgInput"
                                    onChange={e => handleFileChange(e)}
                                    accept="image/*"
                                    title=""
                                />
                            </label>
                            <p className="upload_tip">
                                Please upload a picture with a white background in png or jpg format
                            </p>
                        </div>

                        <p className="form_tip">Category Name</p>
                        <Input
                            className="r-input"
                            placeholder="Enter category name"
                            onInput={(e: any) => {
                                const val = window.utils.removeEmojis(e.target.value);
                                setCategoryName(val);
                            }}
                            maxLength={30}
                            value={categoryName}
                        />
                    </section>
                    <div className="r-left" style={{ marginTop: 40 }}>
                        <Button
                            className="r-emBorder_btn"
                            type="primary"
                            style={{ width: '100%', margin: '0 20px 0 0' }}
                            onClick={() => props.setCategoryDialog(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            style={{ width: '100%' }}
                            loading={loading}
                            disabled={!avatar.url && !categoryName}
                            onClick={() => submit()}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </Modal>

            <Tailor
                dialogVisible={avatar.dialogVisible}
                imgFile={avatar.file}
                aspectRatioWidth="180"
                aspectRatioHeight="180"
                getResult={getResult}
                isFile={true}
                closeWindow={stopUplaod}
            />
        </>
    );
};

export default NewCategory;
