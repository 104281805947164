import { Button, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import closeIcon from '@/assets/images/Icons_Cross.png';
import { useNavigate } from 'react-router-dom';
import api from '@/api/user';
import { defaultInfo } from '@/typings/allType';
import { getCompanyTypeText } from '@/typings/apiType';
import TableBox from '@/components/tableBox';
import Pagination from '@/components/pagination';
const PrizeAcquisitionRecord = props => {
    const navigate = useNavigate();
    const back = () => {
        navigate(-1);
    };
    const [totalRecord, setTotalRecord] = useState(100);
    const [state, setState] = useState({
        pageNo: 1,
        search: '',
        startTime: '' as any,
        endTime: '' as any,
        status: '',
        userId: '',
    });
    const [status, setStatus] = useState('showData');
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState<any>([]);
    const columns = [
        {
            title: 'Prize Name',
            dataIndex: '',
            key: 'prizeName',
            render: val => <div className="cell-txt r-left">{val.prizeName ?? '-'}</div>,
        },
        {
            title: 'Points',
            dataIndex: '',
            width: 300,
            key: 'drawPoints',
            render: val => <div className="cell-txt">{val.drawPoints ?? '0'}</div>,
        },
        {
            title: 'Draw Date',
            key: 'drawDate',
            width: 150,
            render: val => <div className="cell-txt">{val.drawDate ?? '-'}</div>,
        },
    ];
    const handlePageChange = prop => {
        setState({ ...state, pageNo: prop });
        getList({ ...state, pageNo: prop });
    };
    const getList = val => {
        if (status === 'loading') return;
        const params = {
            pageNum: val.pageNo,
            pageSize: 10,
            userId: val.userId,
        };
        setStatus('loading');
        api.getUserAwardList(params, res => callback(res));
    };
    const callback = (res: defaultInfo) => {
        const { data, code, message } = res;
        if (code === 200) {
            if (data.records.length > 0) {
                const list = data.records.map(item => {
                    return Object.assign(item, {
                        drawDate: item.drawDate ? window.format.foramtTimeDMY_easy(+item.drawDate) : '-',
                        point: item.point ? window.format.formatPonit(+item.point) : '0',
                    });
                });
                setTableData(list);
                setStatus('showData');
                setTotalRecord(data.total);
            } else {
                setStatus('showData');
                setTableData([]);
                setTotalRecord(data.total);
            }
        } else {
            setStatus('empty');
            window.format.alert('error', message);
        }
    };
    useEffect(() => {
        const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo') ?? '{}');
        if (userInfo) {
            getList({ ...state, userId: userInfo.userId });
            setState({ ...state, userId: userInfo.userId });
        }
    }, []);
    return (
        <>
            {status === 'loading' ? (
                <div className="data_loading flex-center" style={{ height: 'auto' }}>
                    {/* <Spin  /> */}
                    <Spin />
                </div>
            ) : (
                <></>
            )}
            {status === 'showData' ? (
                <div className="user_detailMain table_page page_mainBox">
                    <div className="r-left">
                        <div className="back_box r-left" style={{ height: 42 }} onClick={back}>
                            <i className="back_icon"></i>
                            Back
                        </div>
                    </div>
                    <section className="user-statistice">
                        <p className="top_tips">Prize acquisition record</p>
                    </section>
                    <TableBox
                        status={status}
                        columns={columns}
                        data={tableData}
                        rowKey={val => val.workoutRewardsId}
                        height={false}
                        className="is_clickable r-table table_main click_row"
                        rowClassName={() => 'r-table_row'}
                    />
                    {status === 'showData' && totalRecord > 0 && totalRecord > 10 ? (
                        <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default PrizeAcquisitionRecord;
