import axios from '../utils/index';
import { Pat } from '@/typings/request';
const settingsApi = {
    getPrizeList(data: Pat, callback: (T: any) => void) {
        axios
            .get(`/prize-entity/list?pageNum=${data.pageNum}&pageSize=10&keyword=${data.keyword}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    updatePrize(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/prize-entity/update`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addPrize(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/prize-entity/add`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    changePrizeStatus(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/prize-entity/updateStatus/${data.prizeId}?isOpen=${data.isOpen}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    delPrizr(data: Pat, callback: (T: any) => void) {
        axios
            .delete(`/prize-entity/delete/${data.prizeId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getMemberPrizeList(data: Pat, callback: (T: any) => void) {
        axios
            .get(`/member-prize-entity/list/${data.subscriptionId}?pageNum=${data.pageNum}&pageSize=10`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getMemberUnselectedPrizeList(data: Pat, callback: (T: any) => void) {
        axios
            .get(`/member-prize-entity/list/unselected/${data.subscriptionId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addMemberPrize(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/member-prize-entity/add`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    delMemberPrize(data: Pat, callback: (T: any) => void) {
        axios
            .delete(`/member-prize-entity/delete/${data.memberPrizeId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getMemberOption(callback: (T: any) => void) {
        axios
            .get(`/subscription-entity/list`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editMemberOption(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/subscription-entity/update`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default settingsApi;
