import axios from '../utils/index';
import { Pat } from '@/typings/request';
const settingsApi = {
    getQuestionCategory(callback: (T: any) => void) {
        axios
            .get(`/question-category-entity/list`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    updateCategory(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/question-category-entity/update`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addCategory(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/question-category-entity/add`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteCategory(data: Pat, callback: (T: any) => void) {
        axios
            .delete(`/question-category-entity/delete/${data.questionCategoryId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getQuestionList(data: Pat, callback: (T: any) => void) {
        axios
            .get(`/question-entity/list/${data.questionCategoryId}?pageNum=${data.pageNum}&pageSize=10`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addQuestion(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/question-entity/add`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getQuestionStatistics(data: Pat, callback: (T: any) => void) {
        axios
            .get(`/question-entity/statistics/${data.questionId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editQuestion(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/question-entity/update`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getQuestionInfo(data: Pat, callback: (T: any) => void) {
        axios
            .get(`/question-entity/info/${data.questionId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    delQuestion(data: Pat, callback: (T: any) => void) {
        axios
            .delete(`/question-entity/delete/${data.questionId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default settingsApi;
