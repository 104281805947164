import axios from '../utils/index';
import { Pat, HttpResponse } from '@/typings/request';
const userApi = {
    getUserList(data: Pat, callback: (T: any) => void) {
        axios
            .get(
                `/admin/user/list?pageNum=${data.pageNum}&pageSize=10&startTime=${data.startTime}&endTime=${data.endTime}&keyword=${data.keyword}&subscriptionStatus=${data.status}`,
                {
                    data,
                }
            )
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUserAwardList(data: Pat, callback: (T: any) => void) {
        axios
            .get(`/user-award-entity/list/${data.userId}?pageNum=${data.pageNum}&pageSize=10`, {
                data,
            })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    disableUser(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/user/disabled`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteUser(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/admin/user/delete/${params.userId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUserDetail(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/admin/user/info/${params.userId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default userApi;
