import { Button, Modal, Progress } from 'antd';
import React, { useEffect, useState } from 'react';
import '../index.scss';
import api from '@/api/survey';
import { defaultInfo } from '@/typings/allType';
const QuestionStatistics = props => {
    const [questionInfo, setQuestionInfo] = useState({
        question: '-',
        female: 0,
        male: 0,
        options: [] as defaultInfo[],
    });
    useEffect(() => {
        if (props.statisticsDialog && props.opeationId) {
            api.getQuestionStatistics({ questionId: props.opeationId }, res => {
                const { data, code, message } = res;
                if (code === 200) {
                    let list = [];
                    if (data.answerVos !== null && data.answerVos.length) {
                        list = data.answerVos.map(item => {
                            return Object.assign(item, {
                                option: item.answer ?? '-',
                                percent: item.percent ? Number(item.percent.toFixed(0)) : 0,
                            });
                        });
                    }
                    data.male = data.male ? Number(data.male.toFixed(0)) : 0;
                    data.female = data.female ? Number(data.female.toFixed(0)) : 0;
                    setQuestionInfo({
                        ...data,
                        options: list,
                    });
                } else {
                    window.format.alert('error', message);
                }
            });
        }
    }, [props.statisticsDialog]);
    return (
        <>
            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={props.statisticsDialog}
                closeIcon={<></>}
                onCancel={() => props.setStatisticsDialog(false)}
                footer={''}
                key={1}
                style={{ width: 480 }}
                title={[
                    <div className="modal-top-title" style={{ marginBottom: 30, textAlign: 'left' }}>
                        Statistics
                    </div>,
                ]}
            >
                <div className="modal_form">
                    <section className="statistics-contentBox">
                        {/* <p className="statisticsTip">50 users answered the question</p> */}
                        <p className="ministis_tip">Question</p>
                        <div className="questionChart_box">
                            <p className="question_name">{questionInfo.question ?? '-'}</p>
                            {questionInfo.options.length ? (
                                questionInfo.options.map((val, i) => {
                                    return (
                                        <div key={`question-statistics-${i}`} className="lineChart-box r-left">
                                            <div
                                                className="blueChart_box"
                                                style={{
                                                    width: val.percent === 100 ? '378px' : val.percent * 3.78,
                                                    borderRadius: val.percent === 100 ? '6px' : '6px 0 0 6px',
                                                }}
                                            ></div>
                                            <span className="chart-text r-left">
                                                <span className="ellipsis optionName_box">{val.option} </span>
                                                <span className="pointNew_box">
                                                    ({val.point} {val.point > 1 ? 'points' : 'point'})
                                                </span>
                                            </span>
                                            <span className="chart-num marLeft">{val.percent}%</span>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="r-center">No data</div>
                            )}
                        </div>
                        <p className="ministis_tip" style={{ margin: '16px 0' }}>
                            Respondent Demography
                        </p>
                        <div className="r-left" style={{ justifyContent: 'space-between' }}>
                            <div className="genderBox  r-left">
                                <Progress
                                    className="female-progress"
                                    type="dashboard"
                                    strokeColor="#3ECC92"
                                    strokeWidth={14}
                                    showInfo={true}
                                    size={42}
                                    percent={questionInfo.female}
                                    gapDegree={1}
                                    format={percent => (
                                        <div className="percent_box r-center">
                                            {percent} <span className="minipercent_tip">%</span>{' '}
                                        </div>
                                    )}
                                />
                                <div>
                                    <p className="ministis_tip" style={{ margin: '0 0 6px 0' }}>
                                        of users were
                                    </p>
                                    <p className="gender-text">Female</p>
                                </div>
                            </div>
                            <div className="genderBox  r-left">
                                <Progress
                                    className="male-progress"
                                    type="dashboard"
                                    strokeColor="#5187EF"
                                    strokeWidth={14}
                                    showInfo={true}
                                    size={42}
                                    percent={questionInfo.male}
                                    gapDegree={1}
                                    format={percent => (
                                        <div className="percent_box r-center male-color">
                                            {percent} <span className="minipercent_tip">%</span>{' '}
                                        </div>
                                    )}
                                />
                                <div>
                                    <p className="ministis_tip" style={{ margin: '0 0 6px 0' }}>
                                        of users were
                                    </p>
                                    <p className="gender-text male-color">Male</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="r-left" style={{ marginTop: 40 }}>
                        <Button
                            className="r-emBorder_btn"
                            type="primary"
                            style={{ width: '100%' }}
                            onClick={() => props.setStatisticsDialog(false)}
                        >
                            Okay
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default QuestionStatistics;
