import { Button, Input, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '@/api/survey';
import { defaultInfo } from '@/typings/allType';
import Pagination from '@/components/pagination';
import NewCategory from './components/newCategory';
import QuestionStatistics from './components/questionStatistics';
import FailImg from '@/assets/img/fail-img.png';
const SurveyDetail = props => {
    const navigate = useNavigate();
    useEffect(() => {
        const info = JSON.parse(window.sessionStorage.getItem('questionCategoryInfo') ?? "{id:'}");
        if (info.id) {
            console.log(info);
            setCategoryInfo(info);
            getQsList({ questionCategoryId: info.questionCategoryId, pageNo: 1 });
        } else {
            setStatus('empty');
            window.format.alert('error', 'Parameter error');
        }
    }, []);
    const back = () => {
        navigate(-1);
    };
    const [totalRecord, setTotalRecord] = useState(0);
    const [categoryInfo, setCategoryInfo] = useState({ id: '', categoryName: '', icon: '', questionCategoryId: '' });
    const [state, setState] = useState({
        isSuspended: false,
        pageNo: 1,
    });
    const handlePageChange = prop => {
        setState({ ...state, pageNo: prop });
        getQsList({ questionCategoryId: categoryInfo.questionCategoryId, pageNo: prop });
    };
    const [questionList, setQuestionList] = useState<defaultInfo[]>([]);
    const getQsList = (val: defaultInfo) => {
        api.getQuestionList({ questionCategoryId: val.questionCategoryId, pageNum: val.pageNo }, res => {
            const { data, code, message, metaData } = res;
            if (code === 200) {
                if (data !== null && data.records.length > 0) {
                    const list = data.records.map((item, i) => {
                        return Object.assign(item, { id: i + 1 });
                    });
                    setQuestionList(list);
                    setStatus('showData');
                    setTotalRecord(data.total);
                } else {
                    setStatus('showData');
                    setQuestionList([]);
                    setTotalRecord(0);
                }
            } else {
                setStatus('empty');
                window.format.alert('error', message);
            }
        });
    };
    const [status, setStatus] = useState('loading');
    const [categoryDialog, setCategoryDialog] = useState(false);
    const [statisticsDialog, setStatisticsDialog] = useState(false);
    const [opeationModalOpen, setOpeationModalOpen] = useState(false);
    const [opeationType, setOpeationType] = useState(1);
    const [opeationId, setOpeationId] = useState('');
    const opeationBtnClcik = (type: number, id?: string) => {
        // 1 del all 2 del one 3 change option
        if (type === 3) {
            if (!questionInfo.question) {
                window.utils.alert('error', 'Please enter the question');
                return;
            }
            if (questionInfo.optionList.findIndex(v => !v.option) !== -1) {
                window.utils.alert('error', 'Please enter the option');
                return;
            }
        }
        if (type === 3 && !questionInfo.isEdit) {
            // add  question
            addOrEditQuestion('add');
            return;
        }
        if (type === 3 && questionInfo.isEdit) {
            // edit  question
            const hasModifyOption =
                questionInfo.optionList.length !== questionInfoCopy.optionList.length ||
                questionInfoCopy.optionList.some((item, i) => {
                    if (item.option !== questionInfo.optionList[i].option) {
                        return true;
                    }
                });
            if (!hasModifyOption) {
                addOrEditQuestion('edit', false);
                return;
            }
            setQuestionInfo({ ...questionInfo, hasModifyOption: hasModifyOption });
        }
        setOpeationId(id ? id : '');
        setOpeationType(type);
        setOpeationModalOpen(true);
    };

    const [questionDialog, setQuestionDialog] = useState(false);
    const [questionInfoCopy, setQuestionInfoCopy] = useState({
        isEdit: false,
        questionId: '',
        hasModifyOption: false,
        question: '',
        optionList: [
            {
                option: '',
                point: 0,
                key: Date.now(),
            },
            {
                option: '',
                point: 0,
                key: Date.now() - 1,
            },
        ],
    });
    const [questionInfo, setQuestionInfo] = useState({
        isEdit: false,
        questionId: '',
        hasModifyOption: false,
        question: '',
        optionList: [
            {
                option: '',
                point: 0,
                key: Date.now(),
            },
            {
                option: '',
                point: 0,
                key: Date.now() - 1,
            },
        ],
    });
    const addQuestion = (id?: string) => {
        const obj = {
            isEdit: false,
            questionId: '',
            hasModifyOption: false,
            question: '',
            optionList: [
                {
                    option: '',
                    point: 0,
                    key: Date.now(),
                },
                {
                    option: '',
                    point: 0,
                    key: Date.now() - 1,
                },
            ],
        };
        if (id) {
            // edit
            api.getQuestionInfo({ questionId: id }, res => {
                const { data, code, message } = res;
                if (code === 200) {
                    if (data === null) return;
                    obj.isEdit = true;
                    obj.questionId = id;
                    obj.hasModifyOption = false;
                    obj.question = data.question;
                    obj.optionList = data.answerVoList.map(val => {
                        return Object.assign(val, {
                            option: val.answer,
                            point: val.point,
                            key: val.answerId,
                        });
                    });
                    setQuestionInfo(JSON.parse(JSON.stringify(obj)));
                    setQuestionInfoCopy(JSON.parse(JSON.stringify(obj)));
                    setQuestionDialog(true);
                } else {
                    window.format.alert('error', message);
                }
            });
            return;
        }
        setQuestionInfo(JSON.parse(JSON.stringify(obj)));
        setQuestionDialog(true);
    };
    const addOrEditQuestion = (type: string, hasModifyOption?: boolean) => {
        const pat: defaultInfo = {
            answerDtoList: questionInfo.optionList.map(val => {
                return { ...val, answer: val.option, point: val.point };
            }),
            question: questionInfo.question,
            questionCategoryId: categoryInfo.questionCategoryId,
        };
        if (type === 'edit') {
            pat.answerVoList = pat.answerDtoList;
            pat.questionId = questionInfo.questionId;
            pat.hasModifyOption = hasModifyOption;
            api.editQuestion(pat, questionCallback);
            return;
        }
        api.addQuestion(pat, questionCallback);
    };
    const questionCallback = res => {
        const { data, code, message } = res;
        setLoading(false);
        if (code === 200) {
            getQsList({ questionCategoryId: categoryInfo.questionCategoryId, pageNo: state.pageNo });
            setQuestionDialog(false);
            setOpeationModalOpen(false);
        } else {
            window.format.alert('error', message);
        }
    };
    const [loading, setLoading] = useState(false);
    const confirmOpeationClick = () => {
        setLoading(true);
        if (opeationType === 1) {
            // del cate
            api.deleteCategory({ questionCategoryId: categoryInfo.questionCategoryId }, res => {
                const { code, message, data } = res;
                if (code === 200) {
                    window.format.alert('success', message);
                    setLoading(false);
                    setOpeationModalOpen(false);
                    navigate(-1);
                } else {
                    setLoading(false);
                    setOpeationModalOpen(false);
                    window.format.alert('error', message);
                }
            });
        } else if (opeationType === 2) {
            // del question
            api.delQuestion({ questionId: opeationId }, res => {
                const { code, message, data } = res;
                if (code === 200) {
                    setLoading(false);
                    setOpeationModalOpen(false);
                    getQsList({ questionCategoryId: categoryInfo.questionCategoryId, pageNo: state.pageNo });
                } else {
                    setLoading(false);
                    setOpeationModalOpen(false);
                    window.format.alert('error', message);
                }
            });
        } else {
            // edit question
            addOrEditQuestion('edit', questionInfo.hasModifyOption);
        }
    };
    return (
        <>
            {status === 'loading' ? (
                <div className="data_loading flex-center" style={{ height: 'auto' }}>
                    {/* <Spin  /> */}
                    <Spin />
                </div>
            ) : (
                <div className="catr_detailMain page_mainBox">
                    <div className="r-left">
                        <div className="back_box r-left" onClick={back}>
                            <i className="back_icon"></i>
                            Back
                        </div>
                        <Button className="r-dergerDefault_btn marLeft" onClick={() => opeationBtnClcik(1)}>
                            Remove
                        </Button>
                    </div>
                    <section className="category_info">
                        <div className="r-left" style={{ margin: '40px 0' }}>
                            <img
                                src={categoryInfo.icon ? categoryInfo.icon : FailImg}
                                onError={(e: any) => (e.target.src = FailImg)}
                                className="cate_icon"
                                alt=""
                            />
                            <span className="catename_box">{categoryInfo.categoryName ?? '-'}</span>
                            <i className="edit_icon" onClick={() => setCategoryDialog(true)}></i>
                        </div>
                        <div className="r-left questionNum_box">
                            Question <span style={{ marginLeft: 8 }}>({totalRecord ? totalRecord : 0})</span>
                            <span className="marLeft addPrizeBtn r-center" onClick={() => addQuestion()}>
                                Add new question
                            </span>
                        </div>
                        <div className="question-box">
                            {status === 'showData' && questionList.length ? (
                                questionList.map((val, i) => {
                                    return (
                                        <div key={i} className="question-item r-left">
                                            <span
                                                style={{ flex: 1, cursor: 'pointer' }}
                                                onClick={() => {
                                                    setOpeationId(val.questionId);
                                                    setStatisticsDialog(true);
                                                }}
                                            >
                                                {val.question ?? '-'}
                                            </span>
                                            <span className="oprationBox r-left">
                                                <i
                                                    className="edit_icon"
                                                    style={{ marginRight: 40 }}
                                                    onClick={() => addQuestion(val.questionId)}
                                                ></i>
                                                <i
                                                    className="delete_icon"
                                                    onClick={() => opeationBtnClcik(2, val.questionId)}
                                                ></i>
                                            </span>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="question-item r-center">
                                    <span>No data</span>
                                </div>
                            )}
                        </div>
                        {status === 'showData' && totalRecord > 0 && totalRecord > 10 ? (
                            <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
                        ) : (
                            <></>
                        )}
                    </section>

                    {/* del modal*/}
                    <Modal
                        className="r-modal modal_no_padding"
                        maskClosable={false}
                        centered={true}
                        open={opeationModalOpen}
                        closeIcon={<></>}
                        onCancel={() => setOpeationModalOpen(false)}
                        footer={''}
                        key={1}
                        zIndex={1001}
                        style={{ width: 480 }}
                        title={[
                            <div className="modal-top-title" style={{ marginBottom: 30 }}>
                                {opeationType === 1
                                    ? 'Remove survey category'
                                    : opeationType === 2
                                    ? 'Remove question'
                                    : 'Change options'}
                            </div>,
                        ]}
                    >
                        <div className="modal_form">
                            <p className="modal_tips">
                                {opeationType === 1
                                    ? 'Are you sure you want to remove this survey category? All questions in this category will be removed together.'
                                    : opeationType === 2
                                    ? 'Are you sure you want to remove this question?'
                                    : 'Are you sure you want to change the options? The statistics will be cleared after the modification.'}
                            </p>
                            <div className="r-left" style={{ marginTop: 40 }}>
                                <Button
                                    className="r-emBorder_btn"
                                    type="primary"
                                    style={{ width: '100%', margin: '0 20px 0 0' }}
                                    onClick={() => setOpeationModalOpen(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="r-primary_btn"
                                    type="primary"
                                    style={{ width: '100%' }}
                                    loading={loading}
                                    onClick={() => confirmOpeationClick()}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </div>
                    </Modal>

                    <NewCategory
                        categoryDialog={categoryDialog}
                        type={'edit'}
                        val={categoryInfo}
                        setCategoryInfo={setCategoryInfo}
                        setCategoryDialog={setCategoryDialog}
                    ></NewCategory>

                    <QuestionStatistics
                        statisticsDialog={statisticsDialog}
                        opeationId={opeationId}
                        setStatisticsDialog={setStatisticsDialog}
                    ></QuestionStatistics>

                    {/* add or edit */}
                    <Modal
                        className="r-modal modal_no_padding"
                        maskClosable={false}
                        centered={true}
                        open={questionDialog}
                        closeIcon={<></>}
                        onCancel={() => setQuestionDialog(false)}
                        footer={''}
                        key={2}
                        style={{ width: 480 }}
                        title={[
                            <div className="modal-top-title" style={{ marginBottom: 30, textAlign: 'left' }}>
                                {!questionInfo.isEdit ? 'Add new question' : ' Edit question'}
                            </div>,
                        ]}
                    >
                        <div className="modal_form">
                            <section>
                                <p className="form_tip">Question</p>
                                <Input
                                    className="r-input"
                                    placeholder="Enter your question"
                                    value={questionInfo.question}
                                    onInput={(e: any) => {
                                        const val = e.target.value;
                                        questionInfo.question = val;
                                        setQuestionInfo({ ...questionInfo });
                                    }}
                                    maxLength={300}
                                />
                                {questionInfo.optionList.map((item, i) => {
                                    return (
                                        <div key={`option-${item.key}`}>
                                            <div className="form_tip r-left">
                                                Option {i + 1}
                                                {i > 1 ? (
                                                    <span
                                                        className="optionDel_text marLeft"
                                                        onClick={() => {
                                                            questionInfo.optionList.splice(i, 1);
                                                            setQuestionInfo({
                                                                ...questionInfo,
                                                                optionList: [...questionInfo.optionList],
                                                            });
                                                        }}
                                                    >
                                                        Delete
                                                    </span>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <Input
                                                className="r-input"
                                                maxLength={100}
                                                value={item.option}
                                                placeholder={`Enter option ${i + 1}`}
                                                onInput={(e: any) => {
                                                    const val = e.target.value;
                                                    questionInfo.optionList[i].option = val;
                                                    setQuestionInfo({ ...questionInfo });
                                                }}
                                            />
                                            <div className="form_tip r-left" style={{ margin: '8px 0 0 0' }}>
                                                <Input
                                                    className="r-input margL8"
                                                    maxLength={5}
                                                    placeholder="0"
                                                    value={item.point}
                                                    width={360}
                                                    onInput={(e: any) => {
                                                        e.target.value = window.format.validateNumberInput(
                                                            e.target.value
                                                        );
                                                        const val = e.target.value;
                                                        questionInfo.optionList[i].point = val;
                                                        setQuestionInfo({ ...questionInfo });
                                                    }}
                                                />
                                                point(s)
                                            </div>
                                        </div>
                                    );
                                })}
                                <div
                                    className="addOption_text"
                                    onClick={() => {
                                        setQuestionInfo({
                                            ...questionInfo,
                                            optionList: [
                                                ...questionInfo.optionList,
                                                { option: '', point: 0, key: Date.now() },
                                            ],
                                        });
                                    }}
                                >
                                    + Add option
                                </div>
                            </section>
                            <div className="r-left" style={{ marginTop: 40 }}>
                                <Button
                                    className="r-emBorder_btn"
                                    type="primary"
                                    style={{ width: '100%', margin: '0 20px 0 0' }}
                                    onClick={() => setQuestionDialog(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="r-primary_btn"
                                    type="primary"
                                    style={{ width: '100%' }}
                                    disabled={
                                        !questionInfo.question &&
                                        questionInfo.optionList.findIndex(v => !v.option) !== -1
                                    }
                                    loading={loading}
                                    onClick={() => opeationBtnClcik(3)}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            )}
        </>
    );
};

export default SurveyDetail;
