/**
 * @author        r.shouwei <r.shouwei@technehq.com>
 * @date          2024-03-13 12:25:35
 * Copyright © Techne All rights reserved
 */
import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useNavigate } from 'react-router-dom';
import '../index.scss';
import FailImg from '@/assets/img/fail-img.png';
export function SortableItem(props) {
    const navigate = useNavigate();
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props.id,
        transition: {
            duration: 300, // milliseconds
            easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
        },
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <div
                className="category_card r-left"
                onClick={e => {
                    window.sessionStorage.setItem('questionCategoryInfo', JSON.stringify(props.val));
                    navigate('/survey-detail');
                }}
            >
                <img
                    src={props.val.icon ? props.val.icon : FailImg}
                    onError={e => (e.target.src = FailImg)}
                    className="cate_icon"
                    alt=""
                />
                <span className="category_name">{props.val.categoryName ?? '-'}</span>
            </div>
        </div>
    );
}
