import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
const instance = axios.create({
    baseURL: process.env.REACT_APP_URL,
    timeout: 360000,
});
instance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const token = sessionStorage.getItem('admin-satoken');
        if (token) {
            (config.headers as AxiosRequestHeaders)['whatsyouropinion-Admin-satoken'] = token;
        }
        (config.headers as AxiosRequestHeaders)['Accept-Language'] = process.env.REACT_APP_ACCEPT_LANGUAGE ?? 'en-US';
        (config.headers as AxiosRequestHeaders)['timestamp'] = new Date().getTime();
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

const AxiosInterceptor = ({ children }: any) => {
    const navigate = useNavigate();

    useEffect(() => {
        const resInterceptor = (response: any) => {
            const code = response.data.code;
            if (code === 50000 || code === 50001) {
                sessionStorage.clear();
                localStorage.clear();
                navigate('/login');
                return;
            } else {
                if (response.headers['admin-satoken'])
                    sessionStorage.setItem('admin-satoken', response.headers['admin-satoken']);
                return response;
            }
        };

        const errInterceptor = (error: any) => {
            return Promise.reject(error);
        };

        const interceptor = instance.interceptors.response.use(resInterceptor, errInterceptor);

        return () => instance.interceptors.response.eject(interceptor);
    }, [navigate]);

    return children;
};

export default instance;
export { AxiosInterceptor };
