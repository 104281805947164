import React, { useCallback, useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import './index.scss';
import dayjs from 'dayjs';
import api from '@/api/survey';
import { defaultInfo } from '@/typings/allType';
import { useNavigate } from 'react-router-dom';
import NewCategory from './components/newCategory';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    useSensor,
    useSensors,
    TouchSensor,
    MouseSensor,
    DragOverlay,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, rectSortingStrategy } from '@dnd-kit/sortable';
import { SortableItem } from './components/SortableItem';
const Survey = (props: any) => {
    useEffect(() => {
        getList();
    }, []);
    const [status, setStatus] = useState('showData');
    const navigate = useNavigate();
    const [state, setState] = useState({
        total: 0,
    });
    const getList = () => {
        if (status === 'loading') return;
        setStatus('loading');
        api.getQuestionCategory(res => {
            const { data, code, message, metaData } = res;
            if (code === 200) {
                if (data.length > 0) {
                    const list = data.map((item, i) => {
                        return Object.assign(item, { id: i + 1 });
                    });
                    setTableData(list);
                    setState({ total: metaData });
                    setStatus('showData');
                } else {
                    setStatus('showData');
                    setTableData([]);
                }
            } else {
                setStatus('empty');
                window.format.alert('error', message);
            }
        });
    };
    const [tableData, setTableData] = useState<any>([]);
    const [isMoveing, setIsMoveing] = useState(false);
    const [categoryDialog, setCategoryDialog] = useState(false);
    const sensors = useSensors(
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(TouchSensor, {
            // Press delay of 250ms, with tolerance of 5px of movement
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            },
        }),
        useSensor(MouseSensor, {
            // Require the mouse to move by 10 pixels before activating
            activationConstraint: {
                distance: 10,
            },
        })
    );
    function handleDragEnd(event) {
        const { active, over } = event;

        if (active.id !== over.id) {
            // 移动
            setIsMoveing(true);
            const oldIndex = tableData.findIndex(v => v.id == active.id);
            const newIndex = tableData.findIndex(v => v.id == over.id);
            const val = JSON.parse(JSON.stringify(tableData[oldIndex]));
            const pat = {
                categoryName: val.categoryName,
                icon: val.icon,
                questionCategoryId: val.questionCategoryId,
                sort: newIndex + 1,
            };
            setTableData(tableData => {
                return arrayMove(tableData, oldIndex, newIndex);
            });
            api.updateCategory(pat, res => {
                const { data, code, message, metaData } = res;
                setIsMoveing(false);
                if (code === 200) {
                    console.log('success');
                } else {
                    setTableData(tableData => {
                        return arrayMove(tableData, newIndex, oldIndex);
                    });
                    window.format.alert('error', message);
                }
            });
        }
    }
    return (
        <div className="survey_main page_mainBox">
            <h2>Survey </h2>
            <div className="survey_top r-left">
                <div className="categorytit_box">
                    Survey category <span style={{ marginLeft: 8 }}>({state.total ?? 0})</span>
                    <p className="categorytit_tip">Long press and drag the card to change the order freely</p>
                </div>
                <div className="space15"></div>
                <div className="r-left marLeft">
                    <Button className="r-primary_btn" style={{ width: 196 }} onClick={() => setCategoryDialog(true)}>
                        Create new category
                    </Button>
                </div>
            </div>
            {status === 'loading' ? (
                <div className="data_loading flex-center" style={{ height: 'auto' }}>
                    <Spin />
                </div>
            ) : status === 'showData' ? (
                tableData.length ? (
                    <Spin spinning={isMoveing}>
                        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                            <SortableContext items={tableData} strategy={rectSortingStrategy}>
                                <div className="categorycard_list">
                                    {tableData.map((val: defaultInfo) => (
                                        <SortableItem val={val} key={val.id} id={val.id} />
                                    ))}
                                </div>
                            </SortableContext>
                        </DndContext>
                    </Spin>
                ) : (
                    <div className="noMessage_box r-center" style={{ margin: '100px 0' }}>
                        No data
                    </div>
                )
            ) : (
                <></>
            )}

            <NewCategory
                categoryDialog={categoryDialog}
                type="add"
                getList={getList}
                setCategoryDialog={setCategoryDialog}
            ></NewCategory>
        </div>
    );
};
export default Survey;
