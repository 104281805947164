import { Button, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import closeIcon from '@/assets/images/Icons_Cross.png';
import { useNavigate } from 'react-router-dom';
import api from '@/api/user';
import { defaultInfo } from '@/typings/allType';
import { useAliveController } from 'react-activation';
import { getCompanyTypeText } from '@/typings/apiType';
const UserDetail = props => {
    const navigate = useNavigate();
    const back = () => {
        navigate(-1);
    };
    const subscriptionPackageIds = [
        [1, 4, 7],
        [2, 5, 8],
        [3, 6, 9],
    ];
    useEffect(() => {
        const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo') ?? '{}');
        if (userInfo.userId) {
            setStatus('loading');
            api.getUserDetail({ userId: userInfo.userId }, res => {
                setStatus('showData');
                const { code, message, data } = res;
                if (code === 200) {
                    data.point = window.utils.formatPonit(data.point ?? '0');
                    data.questionCount = window.utils.formatPonit(data.questionCount ?? '0');
                    data.lotteryCount = window.utils.formatPonit(data.lotteryCount ?? '0');
                    data.genderShow = data.gender === 1 ? 'Female' : 'Male';
                    data.businessTypes = data.businessTypes ? getCompanyTypeText(data.businessTypes) : '';
                    data.ageShow = data.age ? window.format.foramtTimeAge(+data.age) : '-';
                    data.ageNum = data.age ? window.format.getUserAge(+data.age) : '-';
                    data.createTime = data.createTime ? window.format.foramtTimeDMY_easy(+data.createTime) : '-';
                    data.mobileNumber = data.mobileNumber ? window.format.formatNumber(data.mobileNumber) : '-';
                    data.subscription =
                        data.subscriptionId === '3'
                            ? 'Gold'
                            : data.subscriptionId === '2'
                            ? 'Silver'
                            : data.subscriptionId === '1'
                            ? 'Bronze'
                            : 'Freemium';
                    data.subscriptionExpiryTime =
                        data.subscriptionExpiryTime !== null && data.subscriptionExpiryTime
                            ? window.format.foramtTimeDMY_easy(+data.subscriptionExpiryTime)
                            : '';
                    if (data.subscriptionId !== '0') {
                        const index = subscriptionPackageIds.findIndex(v => v.includes(+data.subscriptionPackageId));
                        data.subYear = index + 1;
                    }
                    if (data.userAwardVos !== null && data.userAwardVos.length) {
                        data.userAwardVos = data.userAwardVos.map(item => {
                            return Object.assign(item, {
                                drawDate: item.drawDate ? window.format.foramtTimeDMY_easy(+item.drawDate) : '-',
                                point: item.point ? window.format.formatPonit(+item.point) : '0',
                            });
                        });
                    }
                    setState({ ...state, userInfo, isSuspended: data.disabled });
                    setUserDetail(data);
                    setStatistics([
                        { label: 'Current reward points', value: data.point },
                        { label: 'Number of survey questions answered', value: data.questionCount },
                        { label: 'Number of prizes drawn', value: data.lotteryCount },
                    ]);
                } else {
                    window.format.alert('error', message);
                }
            });
        }
    }, []);
    const [state, setState] = useState({
        isSuspended: false,
        pageNo: 1,
        userInfo: {} as defaultInfo,
        teamNum: 0,
        subNum: 0,
        projectNum: { active: 0, pending: 0, completed: 0 },
    });
    const [statistics, setStatistics] = useState([
        { label: 'Current reward points', value: '0' },
        { label: 'Number of survey questions answered', value: '0' },
        { label: 'Number of prizes drawn', value: '0' },
    ]);
    const [userDetail, setUserDetail] = useState<any>({
        avatarUrl: '',
        email: '',
        ageShow: '',
        genderShow: '',
        mobileNumber: '',
        subscriptionExpiryTime: '',
        createTime: '',
        subscription: '',
        displayName: '',
        postcode: '',
        subscriptionId: '',
        subYear: '',
        ageNum: '',
        point: '0',
        questionCount: '0',
        lotteryCount: '0',
        questionCategoryVos: [],
        userAwardVos: [],
        isMoreThanFour: false,
    });
    const [status, setStatus] = useState('showData');
    const [opeationModalOpen, setOpeationModalOpen] = useState(false);
    const [opeationType, setOpeationType] = useState(1); // 1 del 2 suspend
    const opeationBtnClcik = (type: number) => {
        setOpeationType(type);
        if (type === 2 && state.isSuspended) {
            // 启用
            api.disableUser({ userId: state.userInfo.userId, status: false }, res => {
                const { code, message, data } = res;
                if (code === 200) {
                    setState({ ...state, isSuspended: false });
                } else {
                    window.format.alert('error', message);
                }
            });
            return;
        }
        setOpeationModalOpen(true);
    };
    const [loading, setLoading] = useState(false);
    const confirmOpeationClick = () => {
        setLoading(true);
        if (opeationType === 1) {
            api.deleteUser({ userId: state.userInfo.userId }, res => {
                const { code, message, data } = res;
                if (code === 200) {
                    window.format.alert('success', message);
                    setLoading(false);
                    setOpeationModalOpen(false);
                    navigate(-1);
                } else {
                    setLoading(false);
                    setOpeationModalOpen(false);
                    window.format.alert('error', message);
                }
            });
        } else {
            api.disableUser({ userId: state.userInfo.userId, status: true }, res => {
                const { code, message, data } = res;
                if (code === 200) {
                    setState({ ...state, isSuspended: true });
                    setLoading(false);
                    setOpeationModalOpen(false);
                } else {
                    setLoading(false);
                    setOpeationModalOpen(false);
                    window.format.alert('error', message);
                }
            });
        }
    };
    return (
        <>
            {status === 'loading' ? (
                <div className="data_loading flex-center" style={{ height: 'auto' }}>
                    {/* <Spin  /> */}
                    <Spin />
                </div>
            ) : (
                <></>
            )}
            {status === 'showData' ? (
                <div className="user_detailMain page_mainBox">
                    <div className="r-left">
                        <div className="back_box r-left" onClick={back}>
                            <i className="back_icon"></i>
                            Back
                        </div>
                        <Button className="r-dergerDefault_btn marLeft" onClick={() => opeationBtnClcik(1)}>
                            Remove
                        </Button>
                    </div>
                    <section className="user_info">
                        <div className="r-left userInfo_top">
                            <p className="user_name">{state.userInfo.displayName ?? '-'}</p>
                            <div
                                onClick={() => opeationBtnClcik(2)}
                                className={`user_statusBox r-center marLeft ${state.isSuspended ? 'suspended' : ''}`}
                            >
                                {!state.isSuspended ? 'Active' : 'Suspend'}
                                <i className="exchange_icon"></i>
                            </div>
                        </div>
                        <div className="userInfo_msg">
                            <div className="r-left usermarb12">
                                <div className="userInfo-box">Gender: {userDetail.genderShow ?? '-'}</div>
                                <div className="userInfo-box">
                                    Age: {userDetail.ageNum ?? '-'}（{userDetail.ageShow ?? '-'}）
                                </div>
                            </div>
                            <div className="r-left usermarb12">
                                <div className="userInfo-box">Phone Number: {userDetail.mobileNumber ?? '-'}</div>
                                <div className="userInfo-box">Email Address: {userDetail.email ?? '-'}</div>
                            </div>
                            <div className="r-left usermarb12">
                                <div className="userInfo-box">
                                    Membership: {userDetail.subscription ?? '-'}{' '}
                                    {`${
                                        userDetail.subscriptionId === '0' ? '' : `(${userDetail.subYear ?? '0'} Year)`
                                    }`}
                                </div>
                                <div className="userInfo-box">Postcode: {userDetail.postcode ?? '-'}</div>
                            </div>
                            <div className="r-left">
                                <div className="userInfo-box">
                                    Exp. Date: {userDetail.subscriptionExpiryTime ?? '-'}
                                </div>
                                <div className="userInfo-box"></div>
                            </div>
                        </div>
                    </section>
                    <section className="user-statistice">
                        <p className="top_tips">Subscription statistics</p>
                        <div className="statistics_top r-left">
                            {statistics.map((item, index) => {
                                return (
                                    <div className="statistics_item" key={`label-${index}`}>
                                        <p className="statistics_item_label">{item.label}</p>
                                        <p className="statistics_item_value">{item.value}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <p className="top_tips">Survey Category</p>
                        <div className="category-list r-left">
                            {userDetail.questionCategoryVos.length !== 0 ? (
                                userDetail.questionCategoryVos.map((item, i) => {
                                    return (
                                        <div className="category-item r-left" key={`cateCard_box-${i}`}>
                                            <img
                                                src={
                                                    item.icon ? item.icon : require('../../../assets/img/fail-img.png')
                                                }
                                                onError={(e: any) =>
                                                    (e.target.src = require('../../../assets/img/fail-img.png'))
                                                }
                                                className="category_icon"
                                                alt=""
                                            />
                                            {item.categoryName}
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="r-center noMessage_box">No setting</div>
                            )}
                        </div>
                        <div className="top_tips r-left" style={{ marginTop: 28 }}>
                            Prize acquisition record
                            {userDetail.isMoreThanFour ? (
                                <span
                                    className="marLeft more-box"
                                    onClick={() => {
                                        window.sessionStorage.setItem(
                                            'userAwardVos',
                                            JSON.stringify(userDetail.userAwardVos)
                                        );
                                        navigate('/user-prize-acquisition-record');
                                    }}
                                >{`View  >`}</span>
                            ) : (
                                <></>
                            )}
                        </div>
                        {userDetail.userAwardVos.length !== 0 ? (
                            <div className="userPrize-box">
                                <div className="prize-itemHeader r-left">
                                    <span style={{ flex: 1 }}>Prize Name</span>
                                    <span className="w200">Points</span>
                                    <span className="w150">Draw Date</span>
                                </div>
                                {userDetail.userAwardVos.map((item, i) => {
                                    return (
                                        <>
                                            {i < 4 ? (
                                                <div className="prize-item r-left" key={`prizeCard-${i}`}>
                                                    <span style={{ flex: 1 }}>{item.prizeName ?? '-'}</span>
                                                    <span className="w200">{item.drawPoints ?? '-'}</span>
                                                    <span className="w150">{item.drawDate ? item.drawDate : '-'}</span>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    );
                                })}
                            </div>
                        ) : (
                            <div className="noMessage_box r-center">No record</div>
                        )}
                    </section>

                    {/* del or suspend  modal*/}
                    <Modal
                        className="r-modal modal_no_padding"
                        maskClosable={false}
                        centered={true}
                        open={opeationModalOpen}
                        closeIcon={<></>}
                        onCancel={() => setOpeationModalOpen(false)}
                        footer={''}
                        key={1}
                        style={{ width: 480 }}
                        title={[
                            <div className="modal-top-title" style={{ marginBottom: 30 }}>
                                {opeationType === 1 ? 'Remove account' : 'Suspend account'}
                            </div>,
                        ]}
                    >
                        <div className="modal_form">
                            <p className="modal_tips">
                                {opeationType === 1 ? (
                                    <span>
                                        Are you sure you want to move this account? <br></br>
                                        After the account is removed, all data for the account will be deleted and the
                                        operation cannot be undone.
                                    </span>
                                ) : (
                                    'Are you sure to suspend this account?  After the account is suspended, the user cannot log in.'
                                )}
                            </p>
                            <div className="r-left" style={{ marginTop: 40 }}>
                                <Button
                                    className="r-emBorder_btn"
                                    type="primary"
                                    style={{ width: '100%', margin: '0 20px 0 0' }}
                                    onClick={() => setOpeationModalOpen(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="r-primary_btn"
                                    type="primary"
                                    style={{ width: '100%' }}
                                    loading={loading}
                                    onClick={() => confirmOpeationClick()}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default UserDetail;
